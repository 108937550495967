/* LoginForm.css */

.login-form-container {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.login-form-container h2 {
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .login-form-container {
    width: 90%;
  }
}

/* InputField.css */
.input-field {
  width: 100%;
  border: none;
  border-bottom: 2px solid #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.input-field:focus {
  outline: none;
  border-bottom: 2px solid #3274d6;
}

.input-field.hidden {
  visibility: hidden;
}

/* SubmitButton.css */

.submit-container {
  text-align: center;
}

.submit-button {
  background-color: #3274d6;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background-color: #285fcd;
}
