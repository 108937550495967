/* ListItem.css */
.list-item {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  padding: 2vw;
  padding-left: 6vw;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none; /* no underline */
}

.list-item:nth-child(-n+3) {
  background-color: #7BB8C1;
}

.list-item:nth-child(-n+3):hover {
  background-color: #43939E;
  color: #FFFFFF;
}

.list-item:nth-last-child(-n+2) {
  background-color: #ACD1D6;
}

.list-item:nth-last-child(-n+2):hover {
  background-color: #43939E;
  color: #FFFFFF;
}

.list-icon {
  float: left;
  /* border: 1px solid #999; */
  border: 0px;
  border-radius: 5px;
  /* box-shadow: 0px 1px 1px #000; */
  max-width: 50px;
}

.list-subject {
  padding-left: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  /* position: relative; */
  /* text-shadow: 0px 1px 1px #111; */
  text-decoration: none;
}

.list-description {
  padding-left: 20px;
  color: #444444;
  display: block;
  font-size: 14px;
  /* text-shadow: 0px 1px 1px #111; */
  text-decoration: none;
}
